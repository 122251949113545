@import './swegon-compat/styles';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'SwegonBrown';
    src:
        local('SwegonBrownRegular'),
        url('./assets/fonts/SwegonBrownRegular.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'SwegonBrown';
    src:
        local('SwegonBrownLight'),
        url('./assets/fonts/SwegonBrownLight.woff') format('woff');
    font-weight: 100;
}

@font-face {
    font-family: 'SwegonBrown';
    src:
        local('SwegonBrownBold'),
        url('./assets/fonts/SwegonBrownBold.woff') format('woff');
    font-weight: bold;
}

@layer base {
    h1 {
        @apply mb-4 text-4xl font-extrabold text-black-75;
    }
    h2 {
        @apply mb-4 text-2xl font-bold text-black-75;
    }

    .local-popup {
        .leaflet-popup-content-wrapper {
            @apply text-white border-white bg-green-dark cursor-pointer;
        }
        .leaflet-popup-content {
            /* @apply min-w-[220px]; */
            /* @apply !w-auto flex items-center justify-center max-w-full; */
        }
        .leaflet-popup-tip {
            @apply text-white border-white bg-green-dark;
        }
    }
}

.leaflet-pane {
    z-index: 1 !important;
}

.leaflet-top {
    z-index: 2 !important;
}
